import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { motion } from "framer-motion";

import questions from "../../media/questions.png";

const Section1 = () => {

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    whileInView: { opacity: 1, y: 0 },
    transition: { duration: 0.8 },
    viewport: { once: true }

  };

  return (
    <Container className="p-lg-5 h-100">
      <Row className="flex-row g-5 my-auto p-3">
        <Col md={6} className="h-auto d-none d-lg-flex">
          <img src={questions} alt="questions" style={{ height: "30em" }} />
        </Col>
        <Col md={6} xs={12} className="p-3">
          <div className="p-lg-5 h-100 questions">
            <h2 className="fw-bold">
              ¿Te imaginas poder monetizar tu contenido audiovisual con tu
              propia marca y disfrutando la tecnología que usa
              <strong> Netflix</strong>?
            </h2>
            <motion.div {...fadeInUp}>
              Presentamos la televisión del futuro, por y para profesionales del
              sector audiovisual, un sistema robusto, potente y escalable que
              permite la emisión del canal directamente en smart TV, web y APPs,
              pudiendo gestionar de manera independiente todo su contenido.
            </motion.div>
            <motion.div className="mt-2" {...fadeInUp} transition={{ delay: 0.5, duration: 0.8 }}>
              Nuestro sistema ofrece la posibilidad de segmentar la audiencia
              para poder lanzar campañas específicas de marketing mediante
              notificaciones PUSH directas, mucho más efectivas que el mailing
              masivo, blog y SMS.
            </motion.div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default Section1;
