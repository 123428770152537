import React from "react";
import { Button, Form } from "reactstrap";

import useFormData from "../../hooks/useFormData";

import CustomInput from "./CustomInput";

const  ContactForm = ()  =>{
  const { errors, handleSubmit, handleChange } = useFormData();
  const inputs = [
    {
      label: "Correo",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Nombre",
      name: "nombre",
      type: "text",
      required: true,
    },
    {
      label: "Nombre de la empresa",
      name: "tel",
      type: "tel",
      required: false,
    },
    {
      label: "Número de teléfono",
      name: "nombreEmpresa",
      type: "text",
      required: false,
    },
    {
      label: "Mensaje",
      name: "text",
      type: "textarea",
      required: false,
    },
  ];

  return (
    <div>
      <h2 className="fw-bold">Empieza con la omnicanalidad</h2>
      <h4 className="pt-2 pb-5">Contacta con nosotros</h4>
      <Form onSubmit={handleSubmit}>
        {inputs.map((input) => (
          <CustomInput
            label={input.label}
            name={input.name}
            type={input.type}
            required={input.required}
            function={handleChange}
            error={errors[input.name]}
            key={input.name}
          ></CustomInput>
        ))}
        <Button className="bgColor">Enviar</Button>
      </Form>
    </div>
  );
}
export default ContactForm;
