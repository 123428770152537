
import { Container } from "reactstrap";


const  Header = () =>{
 // const { translate } = useContext(TranslateContext);
  return (
    <header className="header-bg vh-100">
  
      <Container className="container py-1 mt-5">
        <div className="p-2 text-center row ">
          <h1
            className="mb-3 col-12 text-light fw-bold "
            style={{ fontSize: "4.3em" }}
          >
          </h1>
          <div
            className="row d-flex align-items-center m-auto p-2"
            style={{ width: "700px" }}
          >
            
          </div>
          <h1 className="my-5 col-12 azul" style={{ fontSize: "3.3em" }}>
            OTT
          </h1>
          <p className="mb-3 col-9 text-light p-2 texto text-center m-auto">
            Conecta con tus
            <span className="fw-bold"> usuarios </span>{" "}
            en una vibrante comunidad a través de tu propia
            <span className="fw-bold"> plataforma OTT personalizable </span>de contenidos de vídeo exclusivos y streaming
          </p>
          
        </div>
      </Container>
    </header>
  );
}
export default Header;
