import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import ContactForm from "../../components/shared/ContactForm";

import contactUs from "../../media/contactUs.png";

const  Section10 = ()  => {
  return (
    <Container fluid className="h-100"  >
      <Row className="p-lg-5 p-2">
        <Col xs={12} md={5} className="p-lg-5 p-3 border-start border-5 border-primary text-start"
          style={{ backgroundColor: "rgba(0, 96, 126, 0.04)"}}>
          <ContactForm />
        </Col>
        <Col md={7} className="d-none d-lg-flex flex-end" >
        <img src={contactUs} style={{height: '40em',alignItems: 'end'}} alt="contact"></img></Col>
      </Row>
    </Container>
  );
}
export default Section10;
