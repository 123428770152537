import React from "react";
import { Col, Container, Row } from "reactstrap";

import { motion } from "framer-motion";

const Section6 = () => {
  const contents = [
    {
      title: "Back-end dashboard",
      content: <p>Toma el control de lo que suceda en la plataforma
        <strong> organizando</strong> contenido y
        <strong> gestionando</strong> el material multimedia</p>
    },
    {
      title: "Administra los cobros de la plataforma",
      content: <p>Atrae a tu comunidad y
        <strong> monetiza</strong> tu plataforma
        a través de <strong> suscripciones</strong> personalizadas</p>
    },
    {
      title: "Conoce a tu audiencia con analíticas",
      content: <p>Fideliza a través de una plataforma cerrada con vídeos propios y obtén
        <strong> métricas</strong> mucho más individualizadas</p>
    }
  ];
  const isMobile = window.innerWidth <= 768;

  return (
    <Container className="p-5 d-flex justify-content-center">
      <Row className="p-lg-5">
        <h2 className="fw-bold text-center" style={{ fontSize: "2.3em" }}>
          TEN <span className="text-primary"> TODO </span>EL CONTROL
        </h2>
        <Row className="d-flex flex-column g-3 mt-5 px-lg-2 mx-auto">
          {contents.map((text, index) => (
            <motion.div
            key={index}
            initial={{ x: (index % 2 ? 0: 100), opacity: 0 }}
            whileInView={{ x: (isMobile ? 0 : (index % 2 ? 600 : 0)), opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 2, delay: index * 0.2 }}
            style={{overflow: 'hidden'}}
          >
            <Col md={6} className={`card-${index}`} key={index}>
              <Row className="cards-content p-3 d-flex flex-column justify-content-center align-items-center">
              <h3>
                {text.title}
              </h3>
              <div>
                {text.content}
              </div>
              </Row>

            </Col>
            </motion.div>
          ))}
        </Row>
      </Row>
    </Container>
  );
};

export default Section6;
