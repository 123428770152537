import React from "react";
import { Col, Container, Row } from "react-bootstrap";


import { motion } from "framer-motion";

import OnlineWorld from "../../media/OnlineWorld.png";

const Section2 = () => {

  return (
    <Container className="h-100 vw-100">
      <Row className="d-flex flex-row g-5 my-auto p-3 w-100 mx-auto">
        <Col xs={12} md={6} className="p-lg-3 order-2 order-lg-1">
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
            className="p-2 border-start border-5 border-primary text-start "
            style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
          >
            <div className="p-lg-5 p-2 h-100 questions">
              <h2 className="fw-bold">Omnicanalidad</h2>
              <div className="mt-2">
                Con la estrategia omnicanal, tu contenido se vuelve omnipresente. "Tu contenido disponible en todos los sitios" se hace realidad, conectando de manera efectiva con tu audiencia en cualquier momento y lugar. Obtén presencia completa en todas las plataformas para que tu marca esté siempre al alcance de tus usuarios.
              </div>
            </div>
          </motion.div>
        </Col>
        <Col xs={12} md={6} className="order-1 order-lg-2 h-auto d-flex justify-content-center onLineWorld ">
          <img src={OnlineWorld} alt="img" style={{ height: "30em" }} />
        </Col>
      </Row>
    </Container>
  );
}
export default Section2;
