import React from "react";
import { Col, Container, Row } from "reactstrap";

const texts = [
  {
    title: "Sólida experiencia en el sector audiovisual español o internacional",
    text: "Contamos con un equipo con +15 años de experiencia en el sector  audiovisual y en la creación de contenido digital\n"
  },
  {
    title: "Somos una solución, no un proveedor OTT",
    text: "Ofrecemos la omnicanalidad a través de una plataforma personalizable que permite una mayor conexión con la audiencia"
  },
  {
    title: "Tecnología propia",
    text: "Trabajamos con el desarrollo de nuestras propias herramientas, lo cual nos permite una mayor agilización de procesos y fiabilidad absoluta"
  },
  {
    title: "Orientación al cliente",
    text: "Somos mucho más que una OTT, nos implicamos en cada proyecto con un continuo asesoramiento para asegurar el éxito de nuestros clientes"
  },
  {
    title: "Democratizamos las OTT",
    text: "Aseguramos una máxima eficiencia logrando un gran valor aportado hacia los usuarios"
  }
]

const Section9 = () => {
    return (
      <section className="bg-dark text-light py-5 text-center text-md-start">
        <Container className="p-5 ">
          {texts.map(({ text, title }, index) =>  (
              <Row className="d-flex justify-content-center align-items-center " key={index}>
                <Col xs={12} md={3}>
                  <Row style={{fontSize: '8rem'}} className="h-100 d-flex justify-content-center align-items-center fw-bold" >
                    {index + 1}
                  </Row>
                </Col>
                <Col xs={12} md={6}>
                  <h3 className="fw-bold">{title}</h3>
                  <p>{text}</p>
                </Col>
              </Row>
            )
          )}
        </Container>
      </section>
    )
  }

  export default Section9;
  