//import logo from './logo.svg';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Home from "./views/home";
// import { Cookie } from "./context/cookiesContext";
// import { ModalCookies } from "./components/modals/ModalCookies";
import { Stack } from "react-bootstrap";
import Navbar from "./components/navegation/Navbar";



function App() {
  return (
    <Router>
    <Stack className="overflow-hidden mw-100 min-vh-100">
    <Navbar/>
        {/* <Cookie>
          <ModalCookies /> */}
          <Routes>
            <Route path="/" element={<Home />}></Route>
          </Routes>
        {/* </Cookie> */}
    </Stack>
    </Router>
  );
}

export default App;
