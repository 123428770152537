import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { motion } from "framer-motion";

const Section5 = () => {
  const [show, setShow] = useState(false);
  const secondCardRef = useRef(null);
  const handleShow = () =>  {setShow(true); setTimeout(() => {
    if (secondCardRef.current) {
      secondCardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, 100);};
  

  return (
    <Container fluid className="p-5 bg-secondary d-flex justify-content-center align-items-center flex-column">
      <Row className="d-flex justify-content-center p-5 w-100" onClick={handleShow}>
        <Col md={4} className="p-4 text-warning fw-bold first-card-title">¿Que nos hace imbatibles?</Col>
      </Row>
      {show &&
          <Row ref={secondCardRef} className="p-4 text-light second-card-body " >
            <Col className="mb-2">
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 2, delay: 0.2 }}
              >
                La mayoría de empresas que ofrece soluciones OTT y de streaming no tiene la capacidad técnica ni el equipo profesional humano para desarrollar una arquitectura de servidores propia y se ven obligadas a contratar los servicios de grandes corporaciones. Eso implica pagar grandes importes mensualmente a medida que escalan sus soluciones que deriva en la obtención de un beneficio mucho menor.
              </motion.div>
            </Col>

            <Col >
            <motion.div
                initial={{ y: 110, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 2, delay: 0.4 }}
              ><strong className="text-warning">Kinacu</strong> tiene la tecnología, la capacidad y el potencial para ofrecer la misma calidad y escalabilidad de las grandes a precios mucho más competitivos.</motion.div></Col>
          </Row>
      }
    </Container>
  );
};

export default Section5;
