import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const  Section3 = () =>{
  const benefits = [
    'Internet actualmente es el medio por el que se difunden todos los contenidos audiovisuales, por ello, nuestro objetivo es que los creadores o distribuidores de este tipo de contenido puedan difundirlo a partir de una plataforma que lleve su propio branding en cualquier dispositivo, horario y lugar del mundo.',
    'Elegirás tu modelo de negocio, tanto puede difundir su contenido gratuitamente, cobrar por contenido consumido o cuotas mensuales, como también obtener ingresos a través de anuncios.',
    'Podrás recibir un feedback directo de sus espectadores, mientras que la audiencia podrá elegir y personalizar qué contenido específico contrata y paga.',
    'Capacidad de incluir todo tipo de contenido - noticias, ofertas, concursos/sorteos, programación, etc. al mismo tiempo que permite aprovecharse de todos los datos que van generando las mismas emisiones (big data) y extraer conclusiones (business intelligence).'
  ]

  return (
    <Container fluid className="bg-secondary p-5 text-light  d-flex justify-content-center">
      <Container className="p-l-5 m-auto">
        <h2 className="text-center my-2">Beneficios</h2>
        <Row className="p-l-5 flex-wrap mx-auto justify-content-center text-justify">
          {benefits.map((benefit, index) => (
            <Col md={6} xs={6} key={index} className="benefit">
              {benefit}
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}
export default Section3;