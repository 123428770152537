import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { motion } from "framer-motion";

import caixa from "../../media/caixa.jpg";
import sabadell from "../../media/sabadell.jpg";
import mobile_World_Congress from "../../media/mobile_World_Congress.png";
import miro_in_cube from "../../media/miro_in_cube.png";

const  Section4 = () =>{
  const premios = [
    {
      title: 'Mobile World Congress de Barcelona:',
      placement: 'Pitch Elevator 2018'
    },
    {
      title: 'Seedrocket Sabadell Bank Baleares',
      placement: 'Finalist 2018 '
    },
    {
      title: 'Emprendedores S.XXI Caixabank Baleares',
      placement: 'Semifinalist 2018, 2019 & 2020'
    },
    {
      title: 'TouriSMEshare Barcelona',
      placement: 'Chamber of Commerce 2019'
    },
    {
      title: 'Miró in Cub Baleares',
      placement: '3rd position, Europe 2020'
    },

  ]
  const images = [caixa,sabadell,mobile_World_Congress,miro_in_cube];

  return (
    <Container className="p-5">
      <Row className="p-3 gy-5">
        <Col xs={12} md={7}>
          <h2 className="fw-bold text-start text-secondary text-sm-center">Premios y menciones</h2>
          <div className="pictures">
            {images.map((img,index) => (
              <img src={img} alt={index}/>
            ))}
          </div>
        </Col>
        <Col xs={12} md={5}>
          {premios.map((premio, index) => (
            <motion.div
              key={index}
              initial={{ x: 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 2, delay: index * 0.2 }}
            >
              <div key={index} className="premios mb-4">
                <ul>
                  <li className="title">{premio.title}</li>
                    <ul>
                      <li className="placement">{premio.placement}</li>
                    </ul>
                </ul>
              </div>
            </motion.div>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default Section4;
