
import { useEffect, useState } from "react";
import { Col, Container,Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { motion, useScroll } from "framer-motion";

import classNames from "classnames";

import logoLight from "../../media/logoLight.svg";

const Navbar = () => {
  const { scrollY } = useScroll()
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    return scrollY.onChange((latest) => {
      setIsScrolled(latest > 30)
    })
  }, [scrollY])


  return (
    <motion.nav
      animate={{ backgroundColor: isScrolled ? 'rgba(0, 47, 75, 0.6)': undefined }}
      className={classNames("navigation-bar container-fluid py-3", isScrolled ? "text-body" : "text-light")}
    >
      <Container>
        <Row className="align-items-center">
          <Col xs="auto">
            <NavLink to={"/"}>
              <img style={{ height: "3rem" }} src={logoLight} alt=""/>
              {/* <img style={{ height: "3rem" }} src={isScrolled ? my_club_blue : my_club_light} alt=""/> */}
            </NavLink>
          </Col>
        </Row>
      </Container>
    </motion.nav>
  )
}

export default Navbar
