import { useState, useEffect } from "react";

const useFormData = () => {
  const [form, setForm] = useState({});
  const [inputActual, setInputActual] = useState(null);
  const [errors, setErrors] = useState({});
  const camposObligatorios = ["email", "nombre"];

  useEffect(() => {
    if (inputActual !== null) {
      setErrors({ ...errors, [inputActual]: form[inputActual] ? true : false });
    }
    // eslint-disable-next-line
  }, [form[inputActual]]);

  const handleChange = (event) => {
    setInputActual(event.target.name);
    setForm({ ...form, [event.target.name]: event.target.value });
    console.log("errores ", errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("verficando datos...", form);

    const datosFiltrados = Object.entries(errors)
      .filter(([campo, valor]) => camposObligatorios.includes(campo) && valor)
      .map(([campo, valor]) => campo);
    console.log("datos filtrados ", datosFiltrados);
    datosFiltrados.length === camposObligatorios.length
      ? console.log("está bien")
      : console.log("Falta algún campo obligatorio");
  };
  return { errors, inputActual, handleSubmit, handleChange };
};

export default useFormData;
