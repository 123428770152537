import React from "react";

import Footer from "../../components/navegation/Footer";
import  Header  from "./Header";
import Section1 from "./Section1";
import Section10 from "./Section10";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section9 from "./Section9";

export default function Home() {
  return (
    <React.Fragment>
      <Header />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section9 />
      <Section10 /> 
       <Footer />
    </React.Fragment>
  );
}
