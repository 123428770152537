import React from "react";
import { Col, Container, Row } from "reactstrap";

import logoLight from "../../media/logoLight.svg";

const Footer = () => {
  return (
    <Container fluid className="bg-secondary text-light">
      <div className="container py-5">
        <Row>
          <Col md={6} className=" text-start">
            <img src={logoLight} style={{height: '15em', width: '23em'}} alt=""/>
          </Col>
          <Col md={6}>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2994.9801030079975!2d2.078917376541669!3d41.35278619814002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4983e7a596693%3A0x7ea49b934ad06971!2sAEFA%20LES%20MILLS!5e0!3m2!1ses!2ses!4v1711529667301!5m2!1ses!2ses"
              style={{ border: 0, width: "100%", height: "100%" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default Footer;