import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

import PropTypes from "prop-types";

const CustomInput = (props) => {
  return (
    <FormGroup>
      <Label for={props.id}>
        {props.label}
        {props.required && <span className="text-danger">*</span>}
      </Label>
      <Input
        id={props.id}
        name={props.name}
        type={props.type}
        required={props.required ? true : false}
        onChange={props.function}
      />

      {props.required && props.error != null && !props.error && (
        <div className="text-danger">El {props.name} está vacío</div>
      )}
    </FormGroup>
  );
}
CustomInput.prototype = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  function: PropTypes.func,
  required: PropTypes.bool.isRequired,
  error: PropTypes.bool,
};
export default CustomInput;
